
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, watch, onBeforeMount } from 'vue';
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampEmptyListFeedbackV2,
  CampFooterPaginationControl
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import moment from 'moment';
import { ptBr } from 'element-plus/es/locale';
import { ICompanyData } from '@/store/CompaniesStore';
import { ICompanyResponse } from '../Companies/ListPage.vue';
import { IUserResponse } from '../Users/ListPage.vue';
import { IUserData } from '@/store/UsersStore';
import ModalInfoLog, { ILog } from '@/views/Developer/components/ModalInfoLog.vue';
import { campGoToTopByScrollMode } from '@/composables/Helpers';

interface ILogResponse {
  data: {
    data: {
      data: ILog[],
      current_page: number,
      from: number,
      last_page: number,
      per_page: number,
      to: number,
      total: number,
    },
    error: boolean,
    message: string,
    errorCode: string,
  }
}

type TParam = {
  key: string | number;
  label: string;
  value?: string | number;
}

type TQueryParams = {
  oldest: TParam
  status: TParam
  date: TParam
  success: TParam
  auto: TParam
  company: TParam
  store:TParam
  user: TParam
}

const OLDES_LIST: TParam[] = [
  { key: "", label: "" },
  { key: 0, label: "Mais recente" },
  { key: 1, label: "Mais antigo" },
]

const STATUS_LIST: TParam[] = [
  { key: "", label: "" },
  { key: 0, label: "Pendente aprovação", value: "pendente aprovacao" },
  { key: 1, label: "Aprovado", value: "aprovado" },
  { key: 2, label: "Pendente pagamento", value: "pendente pagamento" },
  { key: 3, label: "Finalizado", value: "finalizado" },
  { key: 4, label: "Cancelada", value: "cancelada" },
  { key: 5, label: "Pago", value: "pago" },
]

const AUTO_LIST: TParam[] = [
  { key: "", label: "" },
  { key: 0, label: "Manual" },
  { key: 1, label: "Automático" },
]

const FEEDBACK_LIST: TParam[] = [
  { key: "", label: "" },
  { key: 0, label: "Erro" },
  { key: 1, label: "Sucesso" },
]

const handlerError = (error: AxiosError | unknown, showTimeAlert: Function) => {
  if(error instanceof AxiosError)
    showTimeAlert(error.response?.data.message, "error")
  else
    showTimeAlert("Algo deu errado!", "error")
}

const QUERY_PARAMS: TQueryParams = {
  oldest: { key: "", label: "" },
  status: { key: "", label: "" },
  date: { key: "", label: "" },
  success: { key: "", label: "", value: "" },
  auto: { key: "", label: "" },
  company: { key: "", label: "" },
  store: { key: "", label: "" },
  user: { key: "", label: "" },
}

const handleQueryParams = (queryParams: TQueryParams) => {
  const obj:TQueryParams = JSON.parse(JSON.stringify(queryParams))
  let query = ""

  if(typeof obj.oldest.key === "number")
    query += `oldest=${obj.oldest.key}&`

  if(typeof obj.status.key === "number") {
    const status = (STATUS_LIST.find(el => el.key === obj.status.key))?.value || ''
    if(status.toString().length > 0)
      query += `status=${status}&`
  }

  if(typeof obj.success.key === "number")
    query += `success=${obj.success.key}&`

  if(typeof obj.auto.key === "number")
    query += `auto=${obj.auto.key}&`

  if(typeof obj.company.key === "number" && obj.company.key > 0)
    query += `company_id=${obj.company.key}&`

  if(typeof obj.store.key === "number" && obj.store.key > 0)
    query += `store_id=${obj.store.key}&`

  if(typeof obj.user.key === "number" && obj.user.key > 0)
    query += `user_id=${obj.user.key}&`

  if(Array.isArray(obj.date.label) && obj.date.label.length >= 2) {
    const startDdate = `${obj.date.label[0]} 00:00:00`
    const endDate = `${moment(obj.date.label[1]).add(1, 'day').format('YYYY-MM-DD')} 00:00:00`
    query += `start_date=${startDdate}&end_date=${endDate}&`
  }

  return query.length ? `?${query.slice(0, -1)}`: ''
}

export default defineComponent({
  name: "RemittancesLogList",
  components: {
    CampHeader,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampEmptyListFeedbackV2,
    ModalInfoLog,
    CampFooterPaginationControl
  },
  setup() {
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const isLoading = ref(true)
    const indexCurrentPage = ref(1)
    const totalPage = ref(1)
    const companyList = ref<ICompanyData[] | null>(null)
    const storeList = ref<ICompanyData[] | null>(null)
    const userList = ref<IUserData[] | null>(null)
    const logList = ref<ILog[] | null>(null)
    const logListRender = ref<ILog[] | null>(null)
    const modalInfoLog = ref<{ log: ILog | null, toggle: boolean }>({
      log: null,
      toggle: true
    })
    const keywordSearch = ref("")
    const queryParamsModel = ref<TQueryParams>(JSON.parse(JSON.stringify(QUERY_PARAMS)))
    const queryParamsDone = ref<TQueryParams>(JSON.parse(JSON.stringify(QUERY_PARAMS)))

    /** Functions */
    async function getDatas() {
      try {
        isLoading.value = true
        type TResponseList = [ ILogResponse, ICompanyResponse, ICompanyResponse, IUserResponse ]
        const [ logsResponse, companiesResponse, storesResponse, usersResponse ]: TResponseList = await Promise.all([
          axios.get('/api/getLogProccessRemittanceList'),
          axios.get('/api/getAllCompanies'),
          axios.get('/api/getAllStores'),
          axios.get('/api/getAllUsers')
        ]);
        logList.value = logsResponse.data.data.data.map(el => ({ ...el })) || null
        companyList.value = companiesResponse.data.data.map(el => ({ ...el })) || null
        storeList.value = storesResponse.data.data.map(el => ({ ...el })) || null
        userList.value = usersResponse.data.data.map(el => ({ ...el })) || null
        indexCurrentPage.value = 1
        totalPage.value = logsResponse.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!logList.value)
        return true
      logListRender.value = logList.value.filter((item) => {
        for (const key in item) {
          const propValue = item[key];
          if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase()))
            return true;
          if (typeof propValue === 'number' && keywordSearch.toString() === propValue.toString())
            return true;
        }
        return false;
      });
      return false
    }

    async function handleSearchLogList() {
      try {
        indexCurrentPage.value = 1
        queryParamsDone.value = JSON.parse(JSON.stringify(queryParamsModel.value))
        isLoading.value = true
        const response: ILogResponse = await axios.get(`/api/getLogProccessRemittanceList${handleQueryParams(queryParamsModel.value)}`)
        const { data } = response.data
        logList.value = data.data.map(el => ({ ...el })) || null
        totalPage.value = response.data.data.last_page
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        isLoading.value = false
      }
    }

    async function handlePaginationRendering(index: number) {
      try {
        queryParamsModel.value = JSON.parse(JSON.stringify(queryParamsDone.value))
        loaderStore.open()
        indexCurrentPage.value = index
        let query = handleQueryParams(queryParamsDone.value)
        query = query.length ? query.replace("?", "&") : ''
        const queryFull = `?page=${indexCurrentPage.value}${query}`
        const response: ILogResponse = await axios.get(`/api/getLogProccessRemittanceList${queryFull}`)
        const { data } = response.data
        logList.value = data.data.map(el => ({ ...el }))
        campGoToTopByScrollMode(false)
      } catch (error) {
        handlerError(error, showTimeAlert)
      } finally {
        loaderStore.close()
      }
    }

    const updateLogListRender = () => logListRender.value = logList.value?.map(el => ({ ...el })) || null

    const handleModalInfoLog = (el: ILog) => {
      modalInfoLog.value.toggle = !modalInfoLog.value.toggle
      modalInfoLog.value.log = { ...el }
    }

    /** Life Cycles */
    onBeforeMount(() => getDatas())

    watch(() => logList.value, () => {
      updateLogListRender()
      handleFilterObjectsByKeyword()
    }, { deep: true })

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      moment,
      ptBr,
      isLoading,
      keywordSearch,
      logListRender,
      companyList,
      storeList,
      userList,
      queryParamsModel,
      OLDES_LIST,
      STATUS_LIST,
      AUTO_LIST,
      FEEDBACK_LIST,
      handleSearchLogList,
      handlePaginationRendering,
      indexCurrentPage,
      totalPage,
      handleModalInfoLog,
      modalInfoLog,
    }
  }
})
